.Header {
    padding-top: 10px;
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    color: rgb(42, 41, 54);
    width: 100%;
}


button.acctButtons {
    height: 40px;
    margin-right: 20px;
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    color: rgb(42, 41, 54);
    padding: 0;
    border: none;
    background: none;
    float: right;
    text-decoration: none;
}

a.acctButtons {
    margin-right: 20px;
    color: rgb(42, 41, 54);
    float: right;
    text-decoration: none;
    padding: 5px;
}

.acctButtons:hover {
    cursor: pointer;
    font-weight: bolder;
    color: white;
    background-color: rgb(102, 3, 19);
    border-radius: 20px;
}

.logo {
    height: 70px;
    border-radius: 40px;
    margin-left: 10px;
    margin-top: 5px;
}

.logo:hover {
    border: 5px solid rgb(184, 128, 136);
}

.logInForm {
    font-weight: bolder;
    font-family: 'Playfair Display', serif;
    max-width: 150px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    border-radius: 5px;
    padding: 10px;
    background-color: rgb(143, 128, 130);
    box-shadow: 20px 11px 21px -8px rgba(0, 0, 0.5, 0.5);
}

input {
    height: 35px;
    border-radius: 5px;
    margin: 5px;
}

.logInSubmit {
    height: 30px;
    border-radius: 5px;
    border: none;
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    color: rgb(42, 41, 54);
}

.logInSubmit:hover {
    cursor: pointer;
    font-weight: bolder;
    color: white;
    background-color: rgb(102, 3, 19);
    border-radius: 20px;
}

@media only screen and (max-width: 341px)
  {
      .headerContainer {
        width: 280px;
        margin-right: auto;
        margin-left: auto;
      }
  }

@media only screen and (max-width: 450px)
  {
      .logo {
          height: 50px;
      }
  }