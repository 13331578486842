.noPosts {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
}

.goToBoard {
    text-decoration: none;
    display: block;
    background-color: rgb(42, 41, 54);
    color: white;
    font-weight: bolder;
    margin-top: 40px;
    border-radius: 20px;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
}

.goToBoard:hover {
    cursor: pointer;
    font-size: 30px;
    border: 5px solid rgb(184, 128, 136);
}