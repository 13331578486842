body {
  line-height: 2.5em;
  background-color: rgb(249, 249, 250)
}

.App-link {
  color: #09d3ac;
}

.App {
  color: rgb(42, 41, 54);
}



