.landing {
    font-family: serif;
    padding-left: 20px;
    padding-right: 20px;
}

h2 {
    color: rgb(42, 41, 54);
    font-family: 'Playfair Display', serif;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
  }
  
  .collabPic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 50px;
    height: 300px;
    width: 300px;
    border-radius: 200px;
  }

h3 {
    text-align: center;
    font-size: 25px;
}

p {
    font-size: 20px;
}