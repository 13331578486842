h1 {
    text-align: center;
}

.profilePic {
    margin-right: auto;
    margin-left: auto;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    border-radius: 30px;
}

p {
    padding-left: 20px;
}

.addButton {
    width: 200px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    color: rgb(42, 41, 54);
    text-decoration: none;
    border-radius: 20px;  
}

.goTo {
    color: white;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    background-color: rgb(42, 41, 54);
    border-radius: 20px;
    height: 50px;
}

.goTo:hover {
    border: 5px solid rgb(184, 128, 136);
    cursor: pointer;
    font-weight: bolder;
}

.collabPicProf {
    margin-top: 20px;
    border-radius: 200px;
    height: 200px;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
}

.addButton:hover, a.addProfButton:hover {
    font-weight: bolder;
    cursor: pointer;
}

a.addProfButton {
    color: rgb(42, 41, 54);
    text-decoration: none;
}

.imgContainer {
    text-align: center;
}

.responsive {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 20px;
  }

  .profName {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    color: rgb(102, 3, 19);
  }

  @media only screen and (min-width: 710px)
  {
      .myProfile {
          max-width: 710px;
          margin-right: auto;
          margin-left: auto;
      }

  }

