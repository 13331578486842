.postBoard {
    width: 250px;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    background-color: rgb(143, 128, 130);
    border-radius: 10px;
    box-shadow: 20px 11px 21px -8px rgba(0, 0, 0.5, 0.5);
}

textarea {
    display: block;
    width: 240px;
    border-radius: 10px;
}
