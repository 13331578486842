h1.projectBoard {
    height: 50px;
    color :white;
    background-color: rgb(104, 75, 79);
}

.container {
    width: 100%;
    margin-bottom: 5px;
}

.addPostContainer {
    width: 300px;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
}

a.addPost {
    height: 35px;
    text-decoration: none;
    color: white;
}

button.viewToday {
    text-decoration: none;
    background-color: rgb(102, 3, 19);;
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    color: white;
    width: 150px;
    font-weight: bold;
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    height: 40px;
}

button.viewToday:hover {
    cursor: pointer;
    border: 4px solid rgb(104, 75, 79);
}

.post {
    display: block;
    font-weight: bold;
    font-family: 'Playfair Display', serif;
}

.postSubmit {
    width: 100px;
    border-radius: 5px;
    background: none;
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    background-color: lightgray;
    color: rgb(42, 41, 54);
}

.postingsList {
    font-weight: bold;
    padding-left: 10px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.postings {
    box-shadow: 20px 11px 21px -8px rgba(0, 0, 0.5, 0.5);
    border-radius: 20px;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-left: 30px;
    background-color: rgb(198, 205, 212);
    min-width: 250px;
}

.postProfilePic {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
}

.profileLink {
    text-decoration: none;
    color: rgb(42, 41, 54);
}

.postUsername {
    border-radius: 10px;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    margin-right: 10px;
    margin-top: 9px;
    margin-bottom: 10px;
    display: block;
}

a.profileLink:hover {
    font-size: 35px;
    text-decoration: underline;
    border-radius: 20px;
    padding: 5px;
}

.postDate {
    text-align: center;
    width: 160px;
    font-weight: bold;
    margin-top: 5px;
    height: 40px;
    padding: 5px;
    color: rgb(104, 75, 79);
    border-radius: 10px;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
}

.postDescription {
    margin-left: 0px;
    margin-right: 10px;
}

a.messageLink {
    text-decoration: none;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    color: white;
    background-color: rgb(42, 41, 54);
    border-radius: 10px;
    height: 50px;
    display: block;
    text-align: center;
    margin-bottom: 5px;
}

a.messageLink:hover {    
    font-weight: bolder;
    background-color: rgb(102, 3, 19);
    cursor: pointer;
}

.postImage {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 20px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.backToTop {
    text-decoration: none;
    margin-right: auto;
    margin-left: auto;
    color: white;
    font-weight: bold;
}

.backToTop:hover {
    cursor: pointer;
    color:rgb(102, 3, 19);
}

.top:hover {
    cursor: pointer;
    color:rgb(102, 3, 19);
}

.top {
    text-align: center;
    background-color: rgb(143, 128, 130);
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    border-radius: 20px;
}

.arrow {
    padding-left: 10px;
    padding-right: 10px;
    color: white;
}

.collabPicBoard {
    height: 35px;
    width: 35px;
    border-radius: 60px;  
}

.cut {
    color: rgb(129, 68, 68);
    display: block;
}

.loading {
    color: rgb(243, 200, 207);
    font-weight: bold;
    font-family: 'Playfair Display', serif;
}

@media only screen and (min-width: 650px)
  {
      .postings {
          max-width: 590px;
          margin-right: auto;
          margin-left: auto;
      }

      .cut {
          display: inline-block;
      }

      .collabPicBoard {
          margin-right: 20px;
          padding: 0px;
          display: inline-block;
      }

  }

