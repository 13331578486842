.signUpForm {
    display: flex;
    flex-direction: column;
    box-shadow: 20px 11px 21px -8px rgba(0, 0, 0.5, 0.5);
    border-radius: 5px;
    width: 250px;
    padding: 20px;
    margin-top: 40px;
}

.signUpForm {
    margin-right: auto;
    margin-left: auto;
    background-color: rgb(143, 128, 130);
    font-family: 'Playfair Display', serif;
    font-weight: bolder;
}

.signUpInput {
    width: 200px;
}

.signUpSubmit {
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: lightgray;
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    color: rgb(42, 41, 54);
}

.signUpSubmit:hover {
    cursor: pointer;
    font-weight: bolder;
}

.passwordRules {
    font-size: 12px;
}

.red {
    color: rgb(170, 53, 53);
}

